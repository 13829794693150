import React, { useState } from "react";
import { Select, Option } from "@material-tailwind/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADDPARTY, ADDPARTYFail, GetParty, Group, State } from "../../../../Redux/Party/action";
import { useForm } from "react-hook-form"
import { apiurl } from "configs/config"
import { GetAllParty } from "../../../../Redux/Party/action";
import axios from "axios";
import { PriceGroupListMaster } from "../../../../Redux/Master/action";
import toast from "react-hot-toast";

function ItemsModal(props) {
 
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm( {
    defaultValues: {
      state: props.Editvalue.State
    }
  }
  );
  const GetPartyData = useSelector((state) => state.Party.GetPartyData);
  const GroupData = useSelector((state) => state.Master.pricegrouplist);
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const Sopanam_UserId = JSON.parse(localStorage.getItem("Sopanam_UserId"));
  const Statevalue = useSelector((state)=>state.Party.StateData)
  const addparty = useSelector((state) => state.Party.addpartydata);

  console.log("addparty",addparty );
 
  
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(GetParty({ TempleId: TempleID }));
    dispatch(PriceGroupListMaster({ TempleId: TempleID }));
    dispatch(State());
    
  }, []);


 
 
  
  const HandelSave = (data)=>{
    
    
    
   
    if(props.Editvalue){
      dispatch(
        ADDPARTY({
          Id: props.Editvalue.Id,
          Name: data.name,
          Code: data.code,
          Address: data.address,
          PhoneNumber: data.phone_number,
          GSTN: data.gstin,
          UserId: Sopanam_UserId,
          Status: 0,
          SysTime: "2024-03-11T10:14:36.484Z",
          PartyTypeId: data.party||0,
          DefaultPriceGroupId: parseInt(data.group, 10),
          State: data.state,
          TempleId: TempleID
        }
      
        )
        
      );
    }else{
      if( data.name && data.code && data.phone_number&& data.party &&data.group){
        dispatch(
          ADDPARTY({
            Id: 0,
            Name: data.name,
            Code: data.code,
            Address: data.address,
            PhoneNumber: data.phone_number,
            GSTN: data.gstin,
            UserId: Sopanam_UserId,
            Status: 0,
            SysTime: "2024-03-11T10:14:36.484Z",
            PartyTypeId: data.party||0,
            DefaultPriceGroupId: parseInt(data.group, 10),
            State: data.state,
            TempleId: TempleID
          }
        
          )
        );
      }else{
        toast.error("Fill all the mandatory fields")
      }
      
    }





  }

  return (
    <div>
      {" "}
      <div className="fixed inset-0 z-50 overflow-y-auto ">
        <div className="flex min-h-screen items-center justify-center px-4 text-center">
          {/* Background Overlay */}
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>

          {/* Modal Content */}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl sm:w-4/5 sm:max-w-md">
            {/* Modal Header */}
            <div className="flex items-center justify-between pb-3">
              <h3 className="text-lg font-medium text-gray-900">Insert Party</h3>
              <button
                className="text-gray-500 hover:text-gray-600 focus:outline-none"
                onClick={() => {
                  // Handle close modal action
                  props.setModalOpen(!props.ModalOpen);
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className="mt-4 ">
              <form onSubmit={handleSubmit(HandelSave)}>
                {" "}
                <div className="flex">
                  <div className="mb-4 mr-4  flex-1 ">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                      
                    >
                      Name  <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("name")}
                      id="name"
                      defaultValue={props.Editvalue && props.Editvalue.Name}
                      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                  </div>
                  <div className="mb-4  flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Code <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue={props.Editvalue && props.Editvalue.Code}

                      {...register("code")}
                      id="name"
                      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                  </div>
                  
                </div>
                <div className="mb-4 ">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    type="text"
                    defaultValue={props.Editvalue && props.Editvalue.Address}

                    {...register("address")}
                    id="name"
                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 ">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State <span className="text-red-500">*</span>
                  </label>
                  <select
                        id="version"
                        // defaultValue={props.Editvalue && props.Editvalue.State}
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        {...register("state")}
                        defaultValue={props.Editvalue && props.Editvalue.State}
                        >
                        <option value="">Select State</option>
                        {Array.isArray(Statevalue) &&
                          Statevalue.map((party, index) => {
                           
                            return (<option key={index} value={party.Id} selected={party.Id === props.Editvalue.State}>
                              {party.State}
                            </option>
                          )})}
                      </select>
                </div>
                <div className="flex">
                  <div className="mb-4 mr-4 flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      {...register("phone_number")}
                      id="name"
                      defaultValue={props.Editvalue && props.Editvalue.PhoneNumber}

                      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                  </div>
                  <div className="mb-4 flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      GSTIN <span className="text-red-500"></span>
                    </label>
                    <input
                      type="text"
                      defaultValue={props.Editvalue && props.Editvalue.GSTN}

                      {...register("gstin")}
                      id="name"
                      class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="mb-4 mr-4 flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Party Type <span className="text-red-500">*</span>
                    </label>
                    <div>
                      {/* <Select label="Select Version">
                      {Array.isArray(GetPartyData) &&
                        GetPartyData.map((party, index) => (
                          <Option key={index}>{party.Name}</Option>
                        ))}
                    </Select> */}
                      <select
                       
                        id="version" 
                        {...register("party")}
                        defaultValue={props.Editvalue && props.Editvalue.PartyTypeId}
                       
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      >
                        <option value="">Select Party Type</option>
                        {Array.isArray(GetPartyData) &&
                          GetPartyData.map((party, index) => (
                            <option key={index} value={party.Id}  selected={party.Id === props.Editvalue.PartyTypeId}>
                              {party.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-4 flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                     Price Group <span className="text-red-500">*</span>
                    </label>
                    <div>
                      <select
                        {...register("group")}
                        id="version"
                        defaultValue={props.Editvalue && props.Editvalue.DefaultPriceGroupId}

                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      >
                        <option value="">Select Price Group</option>
                        {Array.isArray(GroupData) &&
                          GroupData.map((party, index) => (
                            <option key={index} value={party.Id} selected={party.Id === props.Editvalue.DefaultPriceGroupId}>
                              {party.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-4 flex-1">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            />
          </div> */}
                {/* Add more input fields for address, GST, code, and others */}
                {/* Modal Footer */}
                <div className="mt-5 flex justify-end sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                   
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsModal;
