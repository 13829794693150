import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import * as XLSX from 'xlsx';
import { RiFileExcel2Line } from "react-icons/ri";
import { IoPrintOutline } from "react-icons/io5";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactSwitch from 'react-switch';
import { useDispatch, useSelector } from "react-redux";
import { StockReport, TransactionReport } from "../../../Redux/Report/action";
import { IoEyeSharp } from "react-icons/io5";
import { MdOutlineFilterAlt } from "react-icons/md";
import { MdOutlineFilterAltOff } from "react-icons/md";
import { filter } from "@chakra-ui/system";



const TransactionReportpage = () => {

    const [SelectType, setSelectType] = useState("1");
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [checked, setChecked] = useState(false);
    const [Filter, setFilter] = useState(false)
//     const formattedfromDate = new Date(fromDate).toISOString();
// const formattedtoDate = new Date(toDate).toISOString();
const [filters, setFilters] = useState({
  BillNumber: '',
  PartyName: '',
  item: '',
  startDate: '',
  endDate: '',
});
const fromdate = new Date(fromDate);
const transactionlist = useSelector((state) => state.Report.transaction);
fromdate.setUTCHours(0);
fromdate.setUTCMinutes(0);
fromdate.setUTCSeconds(0);

const formattedfromDate = fromdate.toISOString().slice(0, 10) + " 00:00:00.000";

const todate = new Date(toDate);

todate.setUTCHours(0);
todate.setUTCMinutes(0);
todate.setUTCSeconds(0);

const formattedtoDate = todate.toISOString().slice(0, 10) + " 00:00:00.000";


// Function to handle input change for filters
const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters({
    ...filters,
    [name]: value,
  });
};

useEffect(() => {
  console.log("filters",filters);
  
  const filtered = transactionlist?.length>0 && transactionlist.filter((item) => {
    return (
      (!filters.BillNumber || item.BillNumber.includes(filters.BillNumber)) &&
      (!filters.BillDate || formatDate(item.BillDate).includes(filters.BillDate)) &&
      (!filters.PartyName || item.PartyName.toLowerCase().includes(filters.PartyName.toLowerCase())) &&
      (!filters.item || item.item.toLowerCase().includes(filters.item.toLowerCase()))
    );
  });
  setFilteredData(filtered);
}, [filters, transactionlist]);


    const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
    const stocklist =useSelector((state)=>state.Report.stock)

    // const totalamount =
    // transactionlist.length &&
    // transactionlist?.reduce((total, item) => total + item.BillAmount, 0);

    const totalamount =
    transactionlist.length &&
    Array.isArray(transactionlist)?transactionlist?.reduce((total, item) => total + item.BillAmount, 0):0
    console.log("totalamount",totalamount);

    const handleChange = val => {
      setChecked(val)
    }

const dispatch =useDispatch();

const [filteredData, setFilteredData] = useState(transactionlist);

useEffect(()=>{
    dispatch(TransactionReport({
        Type:SelectType,
        TempleId:TempleID,
        From:formattedfromDate,
        To:formattedtoDate
    }))
},[formattedfromDate,formattedtoDate,SelectType])




const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    // Pad single digits with leading zeros
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
  
    return `${formattedDay}/${formattedMonth}/${year}`;
  };



  return (
    <div>

    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
        Transaction Report 
        </div>
        </div>
      <div className="relative flex ">
       
        <div className="pb-5 mt-4">
    
         <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            placeholderText="From Date"
            dateFormat="dd/MM/yyyy"
            selectsStart
            className="mr-5"
          />
         </div>
         <div className="mt-4">
         <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            placeholderText="To Date"
            selectsEnd
            dateFormat="dd/MM/yyyy"
            className="mr-5"
          />
        </div>
        <div className="pb-5 mr-6 text-xl font-bold text-navy-700 dark:text-white">
        <select
                className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                id="party"
                value={SelectType}
                onChange={(e) => setSelectType(e.target.value)}
              >
          <option value="0">Select Type</option>
          <option value="1">Issue</option>
          <option value="6">Issue Return</option>
          <option value="2">Purchase</option>
          <option value="4">Purchase Order</option>
          <option value="7">Purchase Return</option>
          <option value="5">Damage</option>
        </select>
        </div>
        <div className="flex justify-end  w-full">{Filter?<MdOutlineFilterAltOff size={28} onClick={()=>{setFilter(false)}}/>:<MdOutlineFilterAlt size={28} onClick={()=>{setFilter(true)}}/>}</div>
        </div>
{Filter?<div> <div className="mb-4 grid grid-cols-4 gap-4">
        <input
          type="text"
          name="BillNumber"
          value={filters.BillNumber}
          onChange={handleFilterChange}
          placeholder="Filter by Bill No"
          className="border p-2"
        />
        <input
          type="text"
          name="BillDate"
          value={filters.BillDate}
          onChange={handleFilterChange}
          placeholder="Filter by Date"
          className="border p-2"
        />
        <input
          type="text"
          name="PartyName"
          value={filters.PartyName}
          onChange={handleFilterChange}
          placeholder="Filter by Party Name"
          className="border p-2"
        />
        <input
          type="text"
          name="item"
          value={filters.item}
          onChange={handleFilterChange}
          placeholder="Filter by Item Name"
          className="border p-2"
        />
      </div>
</div>:''}
      <div className="h-full md:h-[500px] md:overflow-y-auto ">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  SL NO
                </th>
                <th scope="col" className="px-6 py-3">
                Bill No
                </th>
                <th scope="col" className="px-6 py-3">
                Date
                </th>
                <th scope="col" className="px-6 py-3">
               Party Name
                </th>
                <th scope="col" className="px-6 py-3">
               Item Name
                </th>
                <th scope="col" className="px-6 py-3">
                 Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Rate
                </th>
                <th scope="col" className="px-6 py-3">
                Amount
                </th>
                {/* <th scope="col" className="px-6 py-3">
               View
                </th> */}
                

              </tr>
            </thead>
            <tbody>
                
  {Array.isArray(filteredData) && filteredData.map((item, index) => {
    const originalDate = item.BillDate;
    const formattedDate = formatDate(originalDate);
    return(
    <tr key={index} className={`border-b ${index % 2 === 0 ? 'even:bg-gray-50 dark:bg-gray-800' : 'odd:bg-white'} ${index % 2 === 0 ? 'even:bg-gray-800 dark:bg-gray-800' : 'odd:bg-gray-900'}`}>
      <td className="px-6 py-4 text-gray-900">{index + 1}</td>
      <td className="px-6 py-4 text-gray-900">{item.BillNumber}</td>
      <td className="px-6 py-4 text-gray-900">{formattedDate}</td>
      <td className="px-6 py-4 text-gray-900">{item.PartyName}</td>
      <td className="px-6 py-4 text-gray-900">{item.item}</td>
      <td className="px-6 py-4 text-gray-900">{item.Quantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.rate}</td>
      <td className="px-6 py-4 text-gray-900">{item.BillAmount}</td>
    
    </tr>
)})}
<tr>
                  <td colSpan={2} className="mt-1 ">
                    <div
                      className=" mt-5 p-1"
                      style={{ color: "black", marginLeft: "80px" }}
                    >
                      <b>Total :</b>{" "}
                    </div>
                  </td>
                  <td></td>
                  <td>
                    {" "}
                    <div
                      className=" ml-4  mt-5 flex p-1"
                      style={{ color: "black" }}
                    >
                      {" "}
                      {/* <b>{OpeningStockvalue}</b>{" "} */}
                    </div>{" "}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  
                  
                  <td >
                    {" "}
                    <div
                      className=" ml-4  mt-5 flex p-1"
                      style={{ color: "black" }}
                    >
                      {" "}
                      <b>{totalamount} </b>{" "}
                    </div>{" "}
                  </td>
                </tr>
 

</tbody>

          </table>
        </div>
      </div>
    </Card>
    </div>
  );
};

export default TransactionReportpage;
