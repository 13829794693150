import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { GetAllParty, GetParty } from "../../../Redux/Party/action";
import { Listitem } from "../../../Redux/Items/action";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import Select from "react-select";
import PurchaseItemModal from "./PurchaseItemModal";
import {
  PrintPurchase,
  PrintPurchaseFail,
  PurchaseInsert,
  PurchaseInsertFail,
} from "../../../Redux/Sale/action";
import { ResetIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import PurchasePrint from "../../Print/PurchasePrint";
import ReactToPrint from "react-to-print";

function Purchase() {
  const [ChoosedItems, setChoosedItems] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [Qty, setQty] = useState("");
  const [AdvanceAmount, setAdvanceAmount] = useState("");
  const [DiscountAmount, setDiscountAmount] = useState("");
  const [pending, setpending] = useState("");
  const [selectitem, setselectitem] = useState("");
  const [tablelist, setTablelist] = useState([]);

  useEffect(() => {
    console.log("ChoosedItems",ChoosedItems?.Rate);
    
  }, [ChoosedItems])
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: {
      invoiceDate: format(new Date(), "yyyy-MM-dd"),
    },
    mode: "all",
  });

  const Partyvalue = useWatch({
    control,
    name: "party",
  });

  const [PrintOn, setPrintOn] = useState(false);
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const UserId = JSON.parse(localStorage.getItem("Sopanam_UserId"));
  const PartyData = useSelector((state) => state.Party.GetAllPartyData);
  const ItemData = useSelector((state) => state.Items.ListItemData);
  const PurchaseDatavalue = useSelector((state) => state.Sale.PurchaseData);
  const printdata = useSelector((state) => state.Sale.PrintPurchaseData);
  const navigate = useNavigate();

  console.log("PurchaseDatavalue",PurchaseDatavalue);
  const HandelPrint = () => {
    setPrintOn(true);
  };

  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetAllParty(TempleID));
    Dispatch(
      Listitem({
        TempleId: TempleID,
      })
    );
  }, []);

  const TotalAmount = tablelist?.reduce(
    (total, item) => total + item.Rate * item.Quantity,
    0
  );

  useEffect(() => {
    if (PurchaseDatavalue.insertreturn?.Id > 0 && PurchaseDatavalue.Type =='2') {
      toast.success("Purchase success");

      Dispatch(PurchaseInsertFail())
     
    }
  }, [PurchaseDatavalue]);

  const handleitem = (item) => {
    const itemsWithQuantity = { ...item.Item };
    setChoosedItems(itemsWithQuantity);
    setselectitem(item);
    setQty(1);
  };

  const handlecalculation = () => {
    if (Object.keys(ChoosedItems).length > 0) {
      
        const newItem = { ...ChoosedItems, Quantity: parseFloat(Qty) };
        setTablelist([...tablelist, newItem]);
        setChoosedItems([]);
        setQty("");
        setselectitem("");
      
      
    } else {
      alert("Add items");
    }
  };


  const HandelSave = (data) => {
    if (data.party && data.paymentType) {
      let partyid =
        PartyData && PartyData.find((item) => item.Id == data.party);
      let partyname = partyid ? partyid.Name : "";
     if(tablelist.length){
      Dispatch(
        PurchaseInsert({
          Id: 0,
          BillAmount: TotalAmount,
          BarcodeNumber: "string",
          BillDate: "2024-03-13T11:30:28.079Z",
          AdvanceAmount: parseInt(AdvanceAmount) || 0,
          PartyId: parseInt(data.party),
          PartyName: partyname,
          Payment: data.paymentType,
          Discount: parseInt(DiscountAmount) || 0,
          Tax: 0,
          Remarks: data.remarks,
          UserId: UserId,
          TId: 0,
          Amount: TotalAmount,
          OrderDate: "2024-03-13T11:30:28.079Z",
          DeliveryDate: "2024-03-13T11:30:28.079Z",
          BillType: 0,
          BillStatus: 0,
          TransactionItems: tablelist,
          AccountingYearId: 4,
          Type: 2,
          InvoiceNo: "string",
          InvoiceDate: data.invoiceDate,
          Uploads: "string",
          TempleId: TempleID,
        })
      );
     }
     else{
      alert("Please add one Item before Save")
     }
   
    } else {
      alert("Please select the party And payment Type ");
    }
  };

  const handlepending = (adv, dis) => {
    setpending(TotalAmount - dis - adv || TotalAmount);
  };

  const componentRef = React.useRef();
  const HandelClear = () => {
    reset();
    setChoosedItems([]);
    setQty("");
    setselectitem("");
    setTablelist([]);
    Dispatch(PrintPurchaseFail())
    Dispatch(PurchaseInsertFail());
  };
  return (
    <div>
      <PurchasePrint ref={componentRef} transactionId={PurchaseDatavalue?.insertreturn?.Id} />

      <div>
        <div className='class="max-w-sm dark:border-gray-700" mt-5 h-[600px] overflow-y-auto rounded-lg border border-gray-200 bg-white p-6 shadow dark:bg-gray-800'>
          <form onSubmit={handleSubmit(HandelSave)}>
            <div className="container mx-auto">
              <div className=" md:flex  ">
                <div className="mr-2 md:w-1/3">
                  <label
                    for="message"
                    class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Party <span className="text-red-500">*</span>
                  </label>
                  <select
                    {...register("party")}
                    className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id="party"
                  >
                    <option value="">Select Party</option>
                    {Array.isArray(PartyData) &&
                      PartyData.length > 0 &&
                      PartyData.map((party) => (
                        <option key={party.Id} value={party.Id}>
                          {party.Name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mr-2 md:w-1/3 ">
                  <label
                    for="message"
                    class="  mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Invoice Date
                  </label>
                  <input
                    type="date"
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id=""
                    {...register("invoiceDate")}
                  />
                </div>
                <div className=" mr-2 md:w-1/3">
                  <label
                    for="message"
                    class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Invoice Number
                  </label>
                  <input
                    type="text"
                    {...register("invoiceNumber")}
                    className=" block w-full rounded-lg  border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id=""
                  />
                </div>
              </div>
              <div className=" md:flex  ">
                <div className=" mr-2 md:w-2/6">
                  <label
                    for="message"
                    class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Items <span className="text-red-500">*</span>
                  </label>

                  <Select
                    options={
                      ItemData && ItemData.length > 0
                        ? ItemData.map((item) => {
                            return {
                              value: item.Id,
                              label: item.Name,
                              Item: item,
                            };
                          })
                        : []
                    }
                    placeholder="Select Item"
                    value={selectitem}
                    onChange={handleitem}
                    isSearchable={true}
                  />
                </div>

                <div className="mr-2 md:w-1/6 ">
                  <label
                    for="message"
                    class="  mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Quantity <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    value={Qty}
                    min={0}
                    onChange={(e) => {
                      setQty(e.target.value);
                    }}
                  />
                </div>

                <div className="mr-2 md:w-1/6">
                  <label
                    for="message"
                    class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Rate <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="block w-full rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    value={
                      ChoosedItems?.Rate ? ChoosedItems.Rate : ""
                    }
                    onChange={(e) =>
                      setChoosedItems({
                        ...ChoosedItems,
                        Rate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className=" md:flex md:w-2/6">
                  <div className="mr-2 md:w-2/3">
                    <label
                      for="message"
                      class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Total Amount
                    </label>
                    <input
                      type="text"
                      className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                      value={Qty * ChoosedItems.Rate || ""}
                      readOnly
                    />
                  </div>
                  <div className="mr-2 md:w-1/3">
                    <button
                      type="button"
                      class="mt-7 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => {
                        handlecalculation();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              {tablelist.length > 0 ? (
                <div className="mb-5 mt-7 overflow-x-auto border border-green-300">
                  <table className="min-w-full table-auto divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          className="text-large font-xx-large text-black-700 px-8 py-3 text-left uppercase tracking-wider"
                          style={{ width: "40%" }}
                        >
                          <b>Item Name</b>
                        </th>
                        <th
                          className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                        >
                          Quantity
                        </th>
                        <th
                          className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                        >
                          Rate
                        </th>
                        <th
                          className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                        >
                          Amount
                        </th>
                        <th
                          className="text-large font-xx-large text-black-700 px-6 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                          colSpan="2"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {tablelist.map((item, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-8 py-4">
                            {item.Name}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4">
                            {item.Quantity}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4">
                            {item.Rate}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4">
                            {item.Rate && item.Quantity
                              ? `${
                                  (parseFloat(item.Rate) *
                                  parseFloat(item.Quantity))
                                }`
                              : 0}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <button
                              type="button"
                              className="text-red-600 hover:text-red-900"
                              onClick={() => {
                                const newItems = [...tablelist];
                                newItems.splice(index, 1);
                                setTablelist(newItems);
                              }}
                            >
                              <MdDeleteForever
                                className="mt-1"
                                style={{ fontSize: "1.5rem" }}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} className="mt-1 ">
                          <div className=" mt-5 p-2">
                            <b>Total Amount:</b>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="mt-5 flex  justify-center p-2">
                            {" "}
                            <b>{TotalAmount} </b>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 ">
                          <div className="  mt-5 p-2">
                            <b>Discount Amount:</b>{" "}
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className=" !important block w-full   rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 "
                            id=""
                            value={DiscountAmount}
                            onChange={(e) => {
                              setDiscountAmount(e.target.value);
                              handlepending(AdvanceAmount, e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 ">
                          <div className="  mt-5 p-2">
                            <b>Grand Total:</b>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="mt-5 flex  justify-center p-2">
                            {" "}
                            <b>{(TotalAmount - DiscountAmount) || ""} </b>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 ">
                          <div className="  mt-5 p-2">
                            <b>Advance Amount:</b>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <input
                            type="text"
                            className=" !important block w-full   rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 "
                            id=""
                            value={AdvanceAmount}
                            onChange={(e) => {
                              setAdvanceAmount(e.target.value);
                              handlepending(e.target.value, DiscountAmount);
                            }}
                          />{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 ">
                          <div className=" mt-5 p-2">
                            <b>Pending Amount:</b>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="mt-5 flex  justify-center p-2">
                            {" "}
                            <b>{(pending || TotalAmount)} </b>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="flex ">
                <div className="w-full">
                  <label
                    for="message"
                    class=" mt-5 mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Payment Type<span className="text-red-500">*</span>
                  </label>

                  <select
                    {...register("paymentType")}
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id=""
                  >
                    <option value="">Select payment method</option>
                    <option value="0">Cash</option>
                    <option value="1">Card</option>
                    <option value="2">Credit</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  for="message"
                  class="mt-6 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Remarks
                </label>
                <textarea
                  id="message "
                  {...register("remarks")}
                  rows="4"
                  class="mt-4 mb-2 block w-full rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  placeholder="Write your Remarks..."
                ></textarea>
              </div>
              <div className="flex justify-end  bg-saveColor  p-1 pt-2 ">
                {/* <div>
           <button
             type="button"
             class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
           >
             Print
           </button>
         </div> */}
                <div>
                  <button
                    type="submit"
                    class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save
                  </button>
                </div>
                <div>
                  {/* <button
           type="button"
            onClick={()=>{navigate('/Print')}}
             class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
           >
             Print
           </button> */}

                  <ReactToPrint
                    trigger={() => (
                      <button
                        class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button"
                        disabled={!PurchaseDatavalue?.insertreturn?.Id}

                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                    onAfterPrint={() => {
                      if (window.confirm("Are you sure you want to clear data?")) {
                          reset();
                          setChoosedItems([]);
                          setQty("");
                          setselectitem("");
                          setTablelist([]);
                          Dispatch(PrintPurchaseFail());
                          Dispatch(PurchaseInsertFail());
                      }
                  }}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={HandelClear}
                    class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
